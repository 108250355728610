<template>
  <div>
    <div class="top-banner">
      <div class="bg"></div>
      <div class="ban">
        <div class="title-big">American Delivery Service </div>
        <div class="title-small">
          <div>Experience hassle-free, timely delivery of your products.
          </div>
          <div>We allow you to add at most 200 packages in one order. </div>
        </div>
      </div>
    </div>
    <div class="about-us">Contact Us</div>
    <div class="contact-cont">
      <div class="contact-msg">
        <div class="want">
          We'd love to hear from you!
        </div>
        <div class="cat-item">
          <img src="../../assets/images/home/dizhi.svg" />
          <div>
            13 Jensen Dr<br />
            Somerset, NJ, 08873
          </div>
        </div>
        <div class="cat-item">
          <img src="../../assets/images/home/youxiang.svg" />
          <div>
            americandeliveryservice@gmail.com
          </div>
        </div>
        <div class="cat-item">
          <img src="../../assets/images/home/shouji.svg" />
          <div>
            1-718-888-8888
          </div>
        </div>
      </div>
      <div class="contact-form">
        <div class="get-touch">Get In Touch</div>
        <a-form :model="contactForm" autoComplete="off" layout="vertical" ref="contactRef">
          <a-form-item field="name" label="Name" :rules="[
            { required: true, message: 'Please enter name.' }
          ]" :validate-trigger="['blur']">
            <a-input v-model="contactForm.name" placeholder="Please enter" autocomplete="off" />
          </a-form-item>
          <a-form-item field="companyName" label="Company Name">
            <a-input v-model="contactForm.companyName" placeholder="Optional" autocomplete="off" />
          </a-form-item>
          <a-form-item field="email" label="Email" :rules="[
            { required: true, message: 'Please enter email.' },
            { match: /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/, message: 'Please enter correct email format.' }
          ]" :validate-trigger="['blur']">
            <a-input v-model="contactForm.email" placeholder="Please enter" autocomplete="off" />
          </a-form-item>
          <a-form-item field="phone" label="Phone" :rules="[
            { required: true, message: 'Please enter phone.' },
            { minLength: 10, maxLength: 15, message: '10-15 characters' }
          ]" :validate-trigger="['blur']">
            <a-input v-model="contactForm.phone" placeholder="Please enter" autocomplete="off" />
          </a-form-item>
          <a-form-item field="address" label="Address">
            <a-input v-model="contactForm.address" placeholder="Optional" autocomplete="off" />
          </a-form-item>
          <a-form-item field="how" label="How did you hear about us?">
            <a-input v-model="contactForm.how" placeholder="Optional" />
          </a-form-item>
          <a-form-item field="message" label="Message" :rules="[
            { required: true, message: 'Please enter' }
          ]" :validate-trigger="['blur']">
            <a-textarea v-model="contactForm.message" placeholder="Please enter" allow-clear />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" shape="round" style="width:100%;" @click="contactUs"
              :loading="contactLoading">Submit</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
// 引入api中的接口
import { contact } from '@/api/homepage.js'
import { Notification } from '@arco-design/web-vue'

// contact
const contactForm = reactive({
  name: '',
  companyName: '',
  email: '',
  phone: '',
  address: '',
  how: '',
  message: ''
})
const contactRef = ref(null)
const contactLoading = ref(false)
const contactUs = () => {
  contactRef.value.validate(async (value) => {
    if (!value) {
      contactLoading.value = true
      const msg = await contact({
        name: contactForm.name,
        company: contactForm.companyName,
        email: contactForm.email,
        phone: contactForm.phone,
        address: contactForm.address,
        hear_us: contactForm.how,
        message: contactForm.message
      })
      console.log(msg)
      if (msg.code === 0) {
        contactLoading.value = false
        Notification.success({
          title: 'Success',
          content: msg.message
        })
        contactRef.value.resetFields()
      } else {
        contactLoading.value = false
        Notification.error(msg.message)
      }
    }
  })
}
</script>

<style lang="less" scoped>
.top-banner {
  box-sizing: border-box;
  position: relative;
  height: 538px;
  overflow: hidden;

  .bg {
    background-image: url('../../assets/images/home/cangku4.png');
    background-size: cover;
    background-position: 50% 50%;
    height: 540px;
    filter: blur(2px);
  }

  .ban {
    width: 700px;
    position: absolute;
    z-index: 9;
    top: 50%;
    right: 50%;
    transform: translate(50%, -70%);
  }

  .title-big {
    font-weight: 500;
    font-size: 56px;
    color: #fff;
    text-align: center;
    line-height: 64px;
    padding-top: 166px;
  }

  .title-small {
    margin-top: 28px;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    line-height: 28px;
    color: #fff;
  }
}

.about-us {
  text-align: center;
  padding-top: 100px;
  font-weight: 300;
  font-size: 56px;
  line-height: 53px;
  color: #333333;
}

.contact-cont {
  width: 1200px;
  margin: 90px auto;
  position: relative;
  padding-left: 150px;
  box-sizing: border-box;

  .contact-msg {
    width: 450px;
    height: 550px;
    background: rgb(var(--primary-6));
    position: absolute;
    left: 0;
    top: 14%;
    border-radius: 12px;
    color: #fff;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, .1);

    .want {
      font-weight: 500;
      font-size: 44px;
      color: #fff;
      padding: 40px;
    }

    .cat-item {
      display: flex;
      align-items: center;
      padding-left: 24px;
      padding-top: 50px;

      img {
        width: 30px;
      }

      div {
        font-weight: 400;
        font-size: 20px;
        margin-left: 16px;
      }
    }
  }

  .contact-form {
    box-shadow: 5px 5px 25px rgba(0, 0, 0, .1);
    background-color: #fff;
    border-radius: 12px;
    padding-left: 40%;
    padding-top: 50px;
    padding-bottom: 30px;
    padding-right: 10%;

    .get-touch {
      font-weight: 700;
      font-size: 24px;
      color: var(--color-text-1);
      margin-bottom: 20px;
    }
  }
}
</style>
